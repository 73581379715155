import React from "react";
import { NavLink } from "react-router-dom"

export default function NotFound(){
	return (
		<div className="not-found-wrapper">
			<div className="not-found">
				<div className="not-found-404">
					<h3>Page not found</h3>
					<h1>
						<span>4</span>
						<span>0</span>
						<span>4</span>
					</h1>
				</div>
				<h2>We couldn't find the page you looking for.</h2>
				<NavLink className="btn" to="/">
					Home
				</NavLink>
			</div>
		</div>
	)
};
