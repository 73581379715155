import React from 'react';
import { Link, useParams } from 'react-router-dom';
import ScrollButton from '../utils/scrollbutton';
import portfolioData from '../data/portfolioData'

export default function PortfolioItem(props) {
	const {id} = useParams()
	const portfoliItem = portfolioData.find(item => Number(id) === item.id)
	
	//get portfolio item
	let getLink = ""
	if(portfoliItem.live){
		if (portfoliItem.external){
			getLink = (
				<a href={portfoliItem.live} target="_blank" rel="noreferrer noopener">
					<i className="fas fa-eye"></i> Live version{" "}
				</a>
				 )
		} else {
			getLink = (
				<Link to={`../projects/${portfoliItem.live}`}>
					<i className="fas fa-eye"></i> Live version{" "}
				</Link>
			)
		}
	}

	//show framework logos
	const htmlLogo = portfoliItem.fwlogo.map(logo => {
		return  (<img
						src={`../images/${logo}.png`}
						alt={logo}
						className="logo-img"
						key={logo}
					/>)
	})


    return (
			<>
				<ScrollButton />
				<section className="intro portfolio-intro">
					<h1 className="section-title section-title-intro">
						{portfoliItem.title}
						<strong>{portfoliItem.subtitle}</strong>
					</h1>
					<p className="section-subtitle section-subtitle-intro">
						{portfoliItem.framework}
					</p>
					<img
						src={`../images/${portfoliItem.img}`}
						alt={portfoliItem.alt}
						className="intro-img"
					/>
				</section>

				<div className="portfolio-item-individual">
					<div>
						{portfoliItem.github && (
							<a
								href={portfoliItem.github}
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-github"></i> GitHub Repo
							</a>
						)}

						{getLink}
					</div>
					<p>{portfoliItem.description}</p>
					<div className="logo-container">{htmlLogo}</div>
					<p>{portfoliItem.descriptionBottom}</p>
				</div>

				<div className="btn-container">
					<Link to={"/"} className="btn">
						Home
					</Link>
				</div>
			</>
		)
    
}
