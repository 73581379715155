import React from "react"

export default function Card(props) {
	return (
		<div>
			<div className="card__container">
				<div className="card_img_container">
					<img className="card__photo"  alt="" src={props.imageUrl} />
				</div>
				<div className="card__info">
					<p className="card__maps">
						<i className="fas fa-map-marker-alt fa_item"></i>
						<span className="card__location">
							{props.location.toUpperCase()}
						</span>
						<a className="card__link" href={props.googleMapsUrl}>
							View on Google Maps
						</a>
					</p>
					<p className="card__title">{props.title}</p>
					<p className="card__date">
						{props.startDate} - {props.endDate}
					</p>
					<p className="card_description">{props.description}</p>
				</div>
			</div>
			<hr className="card__hr" />
		</div>
	)
}
