const portfolioData = [
	{
		id: 20,
		title: "web for guest management",
		subtitle: "web app",
		framework: "React Node JS",
		img: "vendegkonyv.png",
		alt: "vendegkonyv SPA webpage",
		github: "",
		external: true,
		live: "",
		description: `React app with node js backend. SPA Webpage manage guests, and 
						show information to coworkers, in mobile, tablets and kiosk
						daily and weekly view, and calendar for management`,
		fwlogo: ["react", "node-js", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 19,
		title: "fri-dom.hu",
		subtitle: "webshop app",
		framework: "React Node JS",
		img: "fri-dom.png",
		alt: "fri-dom.hu SPA webpage",
		github: "",
		external: true,
		live: "https://fri-dom.hu",
		description: `React app with node js backend. SPA Webpage fri-dom.hu webshop.
						frontend recaptcha v3 protection, admin page with product management,
						orders management. Backend with oauth authentication, em,ail templates (orders)`,
		fwlogo: ["react", "node-js", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 18,
		title: "rendberak.hu",
		subtitle: "web app",
		framework: "React Node JS",
		img: "rendberak.png",
		alt: "rendberak.hu SPA webpage",
		github: "",
		external: true,
		live: "https://rendberak.hu",
		description: `React app with node js backend. SPA Webpage for my own company.
						smtp backend for contact forms, with recaptcha v3 protection.`,
		fwlogo: ["react", "node-js", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 17,
		title: "bi.lazarteam.hu",
		subtitle: "web app",
		framework: "React app",
		img: "bi-lazarteam.png",
		alt: "bi.lazarteam.hu SPA",
		github: "",
		external: true,
		live: "https://bi.lazarteam.hu",
		description: `React app with node js backend. SPA webpage for BI data visulasitaion
						CBA L és F Kft. daily and monthly data summary in charts and tables`,
		fwlogo: ["react", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 16,
		title: "bi.lazarteam.hu auth",
		subtitle: "backend auth",
		framework: "Node JS",
		img: "lazar-auth.png",
		alt: "bi.lazarteam.hu auth backend",
		github: "",
		external: false,
		live: "",
		description: `Node js backend to server authentication endpoints for CBA L és F Kft. BI fontend appliaction`,
		fwlogo: ["node-js"],
		descriptionBottom: ``,
	},
	{
		id: 15,
		title: "lazarteam api backend",
		subtitle: "backend api",
		framework: "Node JS",
		img: "lazar-api.png",
		alt: "bi.lazarteam.hu api backend",
		github: "",
		external: false,
		live: "",
		description: `Node js backend api to server endpoints for CBA L és F Kft. BI fontend appliaction`,
		fwlogo: ["node-js"],
		descriptionBottom: ``,
	},
	{
		id: 14,
		title: "Tenzies",
		subtitle: "web app",
		framework: "React app",
		img: "tenzies.jpg",
		alt: "Tenzies app",
		github: "https://github.com/sontii/tenzies-react",
		external: false,
		live: "tenzies",
		description: `An implementation of Tenzies Game in ReactJS. A player could track the number of rolls,
						current time and best time it took to win the game. Have Fun.
					  	It was built as a part of Scrimba's Frontend Developer Career Path.`,
		fwlogo: ["react", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 13,
		title: "Quizzical",
		subtitle: "web app",
		framework: "React app",
		img: "quizzical.jpg",
		alt: "quzzical app",
		github: "https://github.com/sontii/quizzical",
		external: false,
		live: "quizzical",
		description: `Quiz WebApp that access trivia questions from the Open Trivia Database API
						and score players based on their answers.
						This is the final Solo Project of Bob Ziroll's React Course on Scrimba
						developed from scratch by me.`,
		fwlogo: ["react", "js", "html", "css"],
		descriptionBottom: `Voluptatibus, soluta blanditiis! Incidunt ea unde itaque illo
						    molestiae eligendi sint culpa nobis voluptas sapiente voluptate,
						    magnam ipsum eius earum?`,
	},
	{
		id: 12,
		title: "Tindog",
		subtitle: "web app",
		framework: "JS & CSS",
		img: "tindog.png",
		alt: "Tinder for dog app",
		github: "https://github.com/sontii/tindog",
		external: true,
		live: "https://sontii.github.io/Tindog/",
		description: `Tinder for dogs. Solo project for scrimba frontend career path: javascript module.`,
		fwlogo: ["js", "html", "css"],
		descriptionBottom: "",
	},
	{
		id: 11,
		title: "Travel journal",
		subtitle: "web app",
		framework: "React & CSS",
		img: "travel journal.jpg",
		alt: "travel journal app",
		github: "https://github.com/sontii/travel-journal",
		external: false,
		live: "travel",
		description: `This is the main layout of an Instagram clone. It is built using
					  vanilla CSS, and is utilizing Flexbox, CSS Grid, and CSS Variables.
					  It was built as a part of Scrimba's Frontend Developer Career Path.`,
		fwlogo: ["react", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 10,
		title: "NAV szűrő",
		subtitle: "Chrome extension",
		framework: "JQuery",
		img: "szurokep.jpg",
		alt: "NAV szuro extension",
		github: "https://github.com/sontii/NAV-color-message",
		external: true,
		live: "https://chrome.google.com/webstore/detail/nav-t%C3%A1rhely-kiemel%C5%91/eifhaolddcapdodnikdmkheojpkmcbci",
		description: `This is a chrome extension for magyarorszag.hu hungarian TAX and Customs Office
						page`,
		fwlogo: ["jquery"],
		descriptionBottom: ``,
	},
	{
		id: 9,
		title: "Order App",
		subtitle: "web app",
		framework: "JS & CSS",
		img: "orderapp.png",
		alt: "order app",
		github: "https://github.com/sontii/orderapp-pureJS",
		live: "https://sontii.github.io/orderapp-pureJS/",
		external: true,
		description: `Solo project for scrimba frontend career path javascript module.`,
		fwlogo: ["js", "html", "css"],
		descriptionBottom: ``,
		figma:
			"https://www.figma.com/file/Hdgwo69Dym9vVsxbuPbl0h/Mobile-Restaurant-Menu?node-id=0%3A1&t=j75Gzn93gBbxxg2r-0",
	},
	{
		id: 8,
		title: "Movie Watchlist App",
		subtitle: "JS app",
		framework: "JS & CSS",
		img: "moviewatchlist.png",
		alt: "movie watchlist app",
		github: "https://github.com/sontii/Movie-watchlist",
		live: "https://sontii.github.io/Movie-watchlist/",
		external: true,
		description: `Create personal watchlist API module. getting data from thecolorapi.com.
					  Solo project for scrimba frontend career path javascript module.`,
		fwlogo: ["js", "html", "css"],
		descriptionBottom: ``,
		figma:
			"https://www.figma.com/file/jhFRdFIdHpRxsDznNXtpXw/Movie-Watchlist?t=ZbMlAaRcQTR9HpP0-0",
	},
	{
		id: 7,
		title: "Color Scheme generator",
		subtitle: "JS app",
		framework: "JS & CSS",
		img: "colorscheme.png",
		alt: "Color Scheme generator app",
		github: "https://github.com/sontii/Color-scheme-generator",
		live: "https://sontii.github.io/Color-scheme-generator/",
		external: true,
		description: `Generate color scheme API module. getting data from omdbapi.com.
					  Solo project for scrimba frontend career path javascript module.`,
		fwlogo: ["js", "html", "css"],
		descriptionBottom: ``,
		figma:
			"https://www.figma.com/file/twasy8Bca4hW7gunLFSLoY/Color-Scheme-Generator?node-id=0%3A1&t=yjDZ8MmOtFM9ndb5-0",
	},
	{
		id: 6,
		title: "CS50 Student attendance",
		subtitle: "Django app",
		framework: "Django",
		img: "cs50-student.png",
		alt: "CS50 student attendance app",
		github: "https://github.com/sontii/cs50web-capstone-student_attendance",
		live: "https://youtu.be/JAumF4IPF3o",
		external: true,
		description: `Student attendace support for school teachers and student keep track their classes, check notes,
					  and communicate each other. Teacher can start courses, set the course capacity, and can assign teacher.
					  Anonym users can explorer classes, registered users, can enroll selected courses, and download notes
					  from courses. Students can send message their teachers. Teacher can check enrolled student in each course,
					  upload notes to course, rate every student, post news.`,
		fwlogo: ["django", "js", "html", "css"],
		descriptionBottom: "",
	},
	{
		id: 5,
		title: "CS50 Web Mail",
		subtitle: "Django app",
		framework: "Django",
		img: "cs50-webmail.png",
		alt: "CS50 webmail app",
		github: "https://github.com/sontii/cs50web-mail",
		live: "",
		external: false,
		description: `single-page-app email client that makes API calls to send and receive emails`,
		fwlogo: ["django", "python", "js", "html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 4,
		title: "firebird to MySql data Migration",
		subtitle: "Python App",
		framework: "Python",
		img: "fbtomysql.png",
		alt: "python app",
		github: "https://github.com/sontii/firebird_to_mysql",
		live: "",
		external: false,
		description: `This application stand for copy daily data to BI sql system.
						Written in python.`,
		fwlogo: ["python"],
		descriptionBottom: ``,
	},
	{
		id: 3,
		title: "Responsive web design",
		subtitle: "HTML page",
		framework: "HTML & CSS",
		img: "responsiveweb.png",
		alt: "html intro",
		github: "https://github.com/sontii/responsive-web-design",
		live: "https://sontii.github.io/responsive-web-design/",
		external: true,
		description: `Responsive web design demo HTML & CSS`,
		fwlogo: ["html", "css"],
		descriptionBottom: ``,
	},
	{
		id: 2,
		title: "Tic-Tac-Toe",
		subtitle: "Python App",
		framework: "Python",
		img: "tictactoe.png",
		alt: "tic tac toe game",
		github: "https://github.com/sontii/me50AI-tictactoe",
		live: "",
		external: true,
		description: `This is an AI driven Tic-Tac-Toe game that runs on the Minimax algorithm`,
		fwlogo: ["python"],
		descriptionBottom: ``,
	},
	{
		id: 1,
		title: "Generate random password",
		subtitle: "JS App",
		framework: "JS & CSS",
		img: "generatepw.png",
		alt: "Generate random password page",
		github: "https://github.com/sontii/generate-password",
		live: "https://sontii.github.io/generate-password/",
		external: true,
		description: `Generate random password.
					  Solo project for scrimba frontend career path javascript module.`,
		fwlogo: ["js", "html", "css"],
		descriptionBottom: ``,
	},
]
export default portfolioData