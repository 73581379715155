import React, { useState } from "react"

export default function ScrollButton() {
	const [visible, setVisible] = useState(false)

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop
		if (scrolled > 300) {
			setVisible(true)
		} else if (scrolled <= 300) {
			setVisible(false)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
			/* you can also use 'auto' behaviour
         in place of 'smooth' */
		})
	}

	window.addEventListener("scroll", toggleVisible)

	return (
		<div className="ui-to-top">
			<i
				className={`fa-solid fa-angle-up top-button ${
					visible ? "scroll-active" : "scroll-inactive"
				}`}
				onClick={scrollToTop}
			></i>
		</div>
	)
}
