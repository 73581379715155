import React from "react"
import { Routes, Route } from "react-router-dom"
import Nav from './components/Nav'
import Main from './pages/Main'
import Footer from "./components/Footer"
import PortfolioItem from "./pages/PortfolioItem"
import ProjectTravel from "./projects/travel/Travel"
import Quizzical from "./projects/quizzical/Quizzical"
import Tenzies from "./projects/tenzies/Tenzies"
import NotFound from "./pages/notFound"

function App() {
	return (
		<React.Fragment>
			<Nav />
			<Routes>
				<Route exact path="/" element={<Main />}></Route>
				<Route
					exact
					path="/portfolio-info/:id"
					element={<PortfolioItem />}
				></Route>
				<Route
					exact
					path="/projects/travel"
					element={<ProjectTravel />}
				></Route>
				<Route exact path="/projects/quizzical" element={<Quizzical />}></Route>
				<Route exact path="/projects/tenzies" element={<Tenzies />}></Route>
				<Route path="/*" element={<NotFound />} />
			</Routes>
			<Footer />
		</React.Fragment>
	)
}

export default App
