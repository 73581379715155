import React from "react"

export default function Nav() {
	return (
		<div className="trav__nav">
			<i className="fas fa-globe-americas"></i>
			<h2>my travel journal</h2>
		</div>
	)
}
