import React, { useState, useRef } from 'react';
import { Link, useLocation } from "react-router-dom"

export default function Nav(props) {
	const [navbarOpen, setNavbarOpen] = useState(false)
	const checkRef = useRef(null)

	const handleToggle = () => {
		setNavbarOpen(!navbarOpen)
	}

	const handleHamburger = () => {
		setNavbarOpen(!navbarOpen)
		checkRef.current.checked = false
	}

	const location = useLocation()
	let navHtml = ''
	if (location.pathname === '/'){
		navHtml = (
			<>
				<li className="nav-item">
					<a className="nav-link" href="#services" onClick={handleHamburger}>
						My services
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="#about" onClick={handleHamburger}>
						About me
					</a>
				</li>
				<li className="nav-item">
					<a className="nav-link" href="#work" onClick={handleHamburger}>
						My work
					</a>
				</li>
			</>
		)
		
	}

    return (
			<>
				<div className="header">
					<h5 id="dev-logo">
						{"<"}dev_sontii78{" />"}
					</h5>
					<div className="nav-button">
						<a
							className="resume"
							href="/doc/resume.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							<strong>resume</strong>
						</a>

						<div className="hamburger" id="hamburger" onClick={handleToggle}>
							<input type="checkbox" ref={checkRef} />
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
					<nav className={navbarOpen ? "slide" : null}>
						<ul className="nav-list">
							<li className="nav-item">
								<Link className="nav-link" to={"/"} onClick={handleHamburger}>
									Home
								</Link>
							</li>
							{navHtml}
						</ul>
					</nav>
				</div>
			</>
		) 
};
