import React from 'react';

export default function Footer(props) {
    return (
			<>
				<footer className="footer">
					{/* replace with your own email address */}
					<a href="mailto:sontii78@gmail.com" className="footer-link">
						sontii78@gmail.com
					</a>
					<ul className="social-list">
						<li className="social-list-item">
							<a
								className="social-list-link"
								href="https://codepen.io/sontii"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-codepen"></i>
							</a>
						</li>
						<li className="social-list-item">
							<a
								className="social-list-link"
								href="https://github.com/sontii"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-github"></i>
							</a>
						</li>
						<li className="social-list-item">
							<a
								className="social-list-link"
								href="https://www.linkedin.com/in/fridrich-ferenc/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="fab fa-linkedin"></i>
							</a>
						</li>
					</ul>
				</footer>
			</>
		)  
};
