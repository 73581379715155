import React from 'react';
import { Link } from 'react-router-dom'
import portfoliData from '../data/portfolioData'

export default function PortfolioIntro(props) {
    const IntroElement = portfoliData.map((item) => (
			<div key={item.id} className="portfolio-item">
				<Link to={`/portfolio-info/${item.id}`}>
				<div>
					<h4 className='portfolio-title'>{item.title}</h4>
				</div>
					<div className='portfolio-img-container'>
						<img
							src={`images/${item.img}`}
							alt={`images/${item.alt}`}
							className="portfolio-img"
						/>	
					</div>
				</Link>
			</div>
		))
    return <>{IntroElement}</>
};
