import React from 'react';
import PortfolioIntro from '../components/PortfolioIntro';
import ScrollButton from '../utils/scrollbutton';

export default function Main(props) {

    return (
			<main>
				<ScrollButton />
				{/* Introduction */}
				<section className="intro">
					<h1 className="section-title section-title-intro">
						Hi, I am <strong>Fridrich Ferenc</strong>
					</h1>
					<p className="section-subtitle section-subtitle-intro">
						Full-stack Developer
					</p>
					<img
						src="images/profile.jpg"
						alt="Ferenc profile"
						className="intro-img"
					/>
				</section>

				{/* services */}

				<section className="my-services" id="services">
					<h2 className="section-title section-title-services">What I do</h2>
					<div className='services'>
						
						{/* service */}
						<div className="service">
							<h3>Design</h3>
							<p>
								I strive to create beautiful and intuitive web apps, meaning I
								design with both aestetics and usability in mind. You will get
								your designs delivered as Figma files, as websites or both.
							</p>
						</div>

						{/* service */}

						<div className="service">
							<h3>Development</h3>
							<p>
								I am a versatile full-stack developer with a speciality in React
								JS and Node JS. I also have a solid grip of the fundamentals of
								web development using HTML, CSS, JavaScript, and Python.
							</p>
						</div>

					</div>

					<a href="#work" className="btn">
						My Projects
					</a>
				</section>

				{/* About me */}
				<section className="about-me" id="about">
					<h2 className="section-title section-title-about">Who I am</h2>
					<p className="section-subtitle section-subtitle-about">
						Full-stack Developer from Hungary
					</p>

					<div className="about-me-body">
						<p>
							I'm a full-stack developer and designer based out of Budapest, Hungary.
							I love building apps that solve real-world problems, and that
							are delightful to use. My specialities include React JS, Python,
							CSS, Node JS, SQL, and bash script.
						</p>
						<p>
							My background is IT lead in a food industry company, and I have
							a Certificate in Harvard University from CS50 series. I also
							have Scrimba Frontend Developer career path.
						</p>
					</div>
					<img
						src="images/profile2.png"
						alt="Ferenc profile"
						className="about-me-img"
					/>
				</section>

				{/* My Work */}

				<section className="my-work-intro" id="work">
					<h2 className="section_title section_title-about">My projects</h2>
					<p className="section_subtitle section_subtitle-about">
						A selection of my range of work
					</p>

					<div className="portfolio">
						<PortfolioIntro />
					</div>
				</section>
			</main>
		) 
};
