import React from "react"
import { Link } from "react-router-dom"

import data from "./data"
import Nav from "./components/Nav"
import Card from "./components/Card"
import "./travel.css"

export default function App() {
	const travelData = data.map((m) => {
		return <Card key={m.title} {...m} />
	})

	return (
		<div className="container">
			<Nav />
			<div className="trav__main">{travelData}</div>
			<div className="btn-container">
				<Link to={"/"}>
					<div className="btn project-btn">
						Home
					</div>
				</Link>
			</div>
		</div>
	)
}
